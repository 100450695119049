const RoleLink = () =>
    import("@/components/RoleLink");
const AddBook = () =>
    import("@/components/Librarian/AddBook");
const AddBookNew = () =>
    import("@/components/Librarian/AddBookNew");
const PayFineNew = () =>
    import("@/components/Librarian/PayFineNew");
const AddPeriodicals = () =>
    import("@/components/Librarian/AddPeriodicals");
const add_book_periodicals = () =>
    import("@/components/Librarian/add_book_periodicals");
const ViewBookChart = () =>
    import("@/components/Librarian/ViewBookChart");
const IssueBook = () =>
    import("@/components/Librarian/IssueBook");
const TransferIssueBook = () =>
    import("@/components/Librarian/TransferIssueBook");
const BookItem = () =>
    import("@/components/Librarian/BookItem");
const BookItem_periodicals = () =>
    import("@/components/Librarian/BookItem_periodicals");
const bookhistory = () =>
    import("@/components/Librarian/bookhistory");
const bulkedit = () =>
    import("@/components/Librarian/bulkedit");
const opachits = () =>
    import("@/components/Librarian/Reports/opachitsreport");
const AddLocation = () =>
    import("@/components/Librarian/AddLocation");

const CentralLibraryReport = () =>
    import("@/components/Librarian/Reports/CentralLibraryReport");

const booksreadreport = () =>
    import("@/components/Librarian/Reports/booksreadreport");
const BooksNotUsedReport = () =>
    import("@/components/Librarian/Reports/BooksNotUsedReport");
const mark_entry = () =>
    import("@/components/Librarian/mark_entry");
const in_out_report = () =>
    import("@/components/Librarian/Reports/In_out_report");
const ReturnBook = () =>
    import("@/components/Librarian/ReturnBook");
const RenewBook = () =>
    import("@/components/Librarian/RenewBook");
const Viewreceipt = () =>
    import("@/components/Librarian/Viewreceipt");
const WeedOutBook = () =>
    import("@/components/Librarian/WeedOutBook");
const SearchCatalog = () =>
    import("@/components/Librarian/SearchCatalog");
const SearchCatalogWithSeries = () =>
    import("@/components/Librarian/SearchCatalogWithSeries");
const IssueBookReport = () =>
    import("@/components/Librarian/IssueBookReport");
const paid_fine_member = () =>
    import("@/components/Librarian/paid_fine_member");
const ReserveBook = () =>
    import("@/components/Librarian/ReserveBook");
const AddSingleBookLocation = () =>
    import("@/components/Librarian/AddSingleBookLocation");
const ImportBook = () =>
    import("@/components/Librarian/ImportBook");
const ImportBookNew = () =>
    import("@/components/Librarian/ImportBookNew");
const ImportBookItemNew = () =>
    import("@/components/Librarian/ImportBookItemNew");
const ImportBookBills = () =>
    import("@/components/Librarian/ImportBookBills");
const ImportIssueBook = () =>
    import("@/components/Librarian/ImportIssueBook");
const bulkresetusernmae = () =>
    import("@/components/Librarian/BulkResetUsername");
const Reports = () =>
    import("@/components/Librarian/Reports");
const book_delete = () =>
    import("@/components/Librarian/delete_book");
const change_status = () =>
    import("@/components/Librarian/change_status");
const AccessionReport = () =>
    import("@/components/Librarian/Reports/AccessionReport");
const book_bank_report = () =>
    import("@/components/Librarian/Reports/book_bank_report");
const StatusWiseReport = () =>
    import("@/components/Librarian/Reports/StatusWiseReport");
const MediumWiseReport = () =>
    import("@/components/Librarian/Reports/MediumWiseReport");
const OverDueMember = () =>
    import("@/components/Librarian/Reports/OverDueMember");
const DailyTransaction = () =>
    import("@/components/Librarian/Reports/DailyTransaction");
const editonwisereport = () =>
    import("@/components/Librarian/Reports/editonwisereport");
const ExportUserData = () =>
    import("@/components/Librarian/Reports/ExportUserData");
const accession_register_new = () =>
    import("@/components/Librarian/Reports/accession_register_new");
const BillSummaryReport = () =>
    import("@/components/Librarian/Reports/BillSummaryReport");
const BillReport = () =>
    import("@/components/Librarian/Reports/BillReport");
const MemberWise = () =>
    import("@/components/Librarian/Reports/MemberWise");
const AddPeriodicalReport = () =>
    import("@/components/Librarian/Reports/AddPeriodicalReport");
const AddBookReport = () =>
    import("@/components/Librarian/Reports/AddBookReport");
const UserProfile = () =>
    import('@/components/UserProfile');
const new_arrival = () =>
    import("@/components/Librarian/Reports/new_arrival.vue");
const barcode_report = () =>
    import("@/components/Librarian/Reports/barcode_report.vue");
const qrcode_report = () =>
    import("@/components/Librarian/Reports/qrcode_report.vue");
const barcode_report3 = () =>
    import("@/components/Librarian/Reports/barcodeReport3.vue");
const qrcode_report3 = () =>
    import("@/components/Librarian/Reports/qrcodeReport3.vue");
const spine_lable_report = () =>
    import("@/components/Librarian/Reports/Spine_label_report.vue");
const spine_lable_report2 = () =>
    import("@/components/Librarian/Reports/Spine_label_report2.vue");
const barcodeReport2 = () =>
    import("@/components/Librarian/Reports/barcodeReport2.vue");
const qrcodeReport2 = () =>
    import("@/components/Librarian/Reports/qrcodeReport2.vue");
const TitleReport = () =>
    import("@/components/Librarian/Reports/TitleReport");
const missing_book_report = () =>
    import("@/components/Librarian/Reports/missing_book_report");
const DepartmentwiseBookReport = () =>
    import("@/components/Librarian/Reports/DepartmentwiseBookReport");
const SeriesWiseReport = () =>
    import("@/components/Librarian/Reports/SeriesWiseReport");
const PayFine = () =>
    import("@/components/Librarian/PayFine");
const UploadQuestionPaper = () =>
    import("@/components/Librarian/UploadQuestionPaper");
const DownloadQuestionPaper = () =>
    import("@/components/Librarian/DownloadQuestionPaper.vue");
const total_fine_student_wise = () =>
    import("@/components/Librarian/Reports/total_fine_student_wise");
const markstudentbookbank = () =>
    import("@/components/Librarian/markstudentbookbank");
const report_dashboard = () =>
    import("@/components/Librarian/markstudentbookbank");
const issuebookreport = () =>
    import("@/components/Librarian/Reports/IssueBookreport");
const Member_LIb_Not_used_At_all = () =>
    import("@/components/Librarian/Reports/Member_LIb_Not_used_At_all");
const Series_Wise_report = () =>
    import("@/components/Librarian/Reports/Series_Wise_report");
const Book_Card_Report = () =>
    import("@/components/Librarian/Reports/Book_Card_Report");
const Erp_check = () =>
    import('@/components/Librarian/Erp_check');
const LearnerToLibrary = () =>
    import('@/components/Librarian/LearnerToLibrary');
const InstToLibrary = () =>
    import('@/components/Librarian/InstToLibrary');

const routes = [{
    path: "/rolelink",
    name: "rolelink",
    component: RoleLink,
    meta: {
        breadcrumb: "Login" //crumb
    },
    children: [{
        path: '/add-book',
        name: 'add-book',
        component: AddBook,
    },
    {
        path: '/add-book-new',
        name: 'add-book-new',
        component: AddBookNew,
    },
    {
        path: '/pay-fine-new',
        name: 'pay-fine-new',
        component: PayFineNew,
    },
    {
        path: '/mark_entry',
        name: 'mark_entry',
        component: mark_entry,
    },
    {
        path: '/in_out_report',
        name: 'in_out_report',
        component: in_out_report,
    },
    {
        path: '/erp_check_library',
        name: 'erp_check_library',
        component: Erp_check
    },
    {
        path: '/LearnerToLibrary',
        name: 'LearnerToLibrary',
        component: LearnerToLibrary
    },
    {
        path: '/InstToLibrary',
        name: 'InstToLibrary',
        component: InstToLibrary
    },
    {
        path: '/bill-summary-report',
        name: 'bill-summary-report',
        component: BillSummaryReport,
    },
    {
        path: '/bill-report',
        name: 'bill-report',
        component: BillReport,
    },
    {
        path: '/add-periodicals',
        name: 'add-periodicals',
        component: AddPeriodicals,
    },
    {
        path: '/add_book_periodicals',
        name: 'add_book_periodicals',
        component: add_book_periodicals,
    },
    {
        path: '/report-dashboard',
        name: 'report-dashboard',
        component: report_dashboard,
    },
    {
        path: '/issue-book',
        name: 'issue-book',
        component: IssueBook,
    },
    {
        path: '/transfer-issue-book',
        name: 'transfer-issue-book',
        component: TransferIssueBook,
    },
    {
        path: '/booksreadreport',
        name: 'booksreadreport',
        component: booksreadreport,
    },
    {
        path: '/books-notused-report',
        name: 'books-notused-report',
        component: BooksNotUsedReport,
    },
    {
        path: '/view-book-chart',
        name: 'view-book-chart',
        component: ViewBookChart,
    },
    {
        path: '/book-item',
        name: 'book-item',
        component: BookItem,
    },
    {
        path: '/bookitem_periodicals',
        name: 'bookitem_periodicals',
        component: BookItem_periodicals,
    },
    {
        path: '/add-location',
        name: 'add-location',
        component: AddLocation,
    },
    {
        path: '/return-book',
        name: 'return-book',
        component: ReturnBook,
    },
    {
        path: '/renew-book',
        name: 'renew-book',
        component: RenewBook,
    },
    {
        path: '/view-receipt',
        name: 'view-receipt',
        component: Viewreceipt,
    },
    {
        path: '/weedout-book',
        name: 'weedout-book',
        component: WeedOutBook,
    },
    {
        path: '/search-catalog',
        name: 'search-catalog',
        component: SearchCatalog,
    },
    {
        path: '/search-catalog-with-series',
        name: 'search-catalog-with-series',
        component: SearchCatalogWithSeries,
    },
    {
        path: '/mark-student-book-bank',
        name: 'mark-student-book-bank',
        component: markstudentbookbank,
    },
    {
        path: '/book-history',
        name: 'book-history',
        component: bookhistory,
    },

    {
        path: '/issue-book-report',
        name: 'issue-book-report',
        component: issuebookreport,
    },
    {
        path: '/Member_Lib_Not_used_At_all',
        name: 'Member_Lib_Not_used_At_all',
        component: Member_LIb_Not_used_At_all,
    },
    {
        path: '/Series_Wise_report',
        name: 'Series_Wise_report',
        component: Series_Wise_report,
    },
    {
        path: '/Book_Card_Report',
        name: 'Book_Card_Report',
        component: Book_Card_Report,
    },
    {
        path: '/bulkedit',
        name: 'bulkedit',
        component: bulkedit,
    },

    {
        path: '/book-delete',
        name: 'book-delete',
        component: book_delete,
    },
    {
        path: '/centrallibrary-report',
        name: 'centrallibrary-report',
        component: CentralLibraryReport,
    },


    {
        path: '/change-status',
        name: 'change-status',
        component: change_status,
    },
    {
        path: '/issue-book-report',
        name: 'issue-book-report',
        component: IssueBookReport,
    },
    {
        path: '/paid_fine_member',
        name: 'paid_fine_member',
        component: paid_fine_member,
    },
    {
        path: '/reserve-book',
        name: 'reserve-book',
        component: ReserveBook,
    },
    {
        path: '/single-book-location',
        name: 'single-book-location',
        component: AddSingleBookLocation,
    },
    {
        path: '/import-book',
        name: 'import-book',
        component: ImportBook,
    },
    {
        path: '/import-book-new',
        name: 'import-book-new',
        component: ImportBookNew,
    },
    {
        path: '/import-bookitem-new',
        name: 'import-bookitem-new',
        component: ImportBookItemNew,
    },
    {
        path: '/import-bookbills',
        name: 'import-bookbills',
        component: ImportBookBills,
    },
    {
        path: '/import-issue-book',
        name: 'import-issue-book',
        component: ImportIssueBook,
    },
    {
        path: '/bulk-reset-username',
        name: 'bulk-reset-username',
        component: bulkresetusernmae,
    },
    {
        path: '/reports',
        name: 'reports',
        component: Reports,
    },
    {
        path: '/accession-report',
        name: 'accession-report',
        component: AccessionReport,
    },
    {
        path: '/book_bank_report',
        name: 'book_bank_report',
        component: book_bank_report,
    },
    {
        path: '/statuswise-report',
        name: 'statuswise-report',
        component: StatusWiseReport,
    },
    {
        path: '/mediumwise-report',
        name: 'mediumwise-report',
        component: MediumWiseReport,
    },
    {
        path: '/new_arrival_report',
        name: 'new_arrival_report',
        component: new_arrival,
    },
    {
        path: '/barcode_report',
        name: 'barcode_report',
        component: barcode_report,
    },
    {
        path: '/qrcode_report',
        name: 'qrcode_report',
        component: qrcode_report,
    },
    {
        path: '/barcode_report3',
        name: 'barcode_report3',
        component: barcode_report3,
    },
    {
        path: '/qrcode_report3',
        name: 'qrcode_report3',
        component: qrcode_report3,
    },
    {
        path: '/spine_label_report',
        name: 'spine_label_report',
        component: spine_lable_report,
    },
    {
        path: '/spine_label_report2',
        name: 'spine_label_report2',
        component: spine_lable_report2,
    },
    {
        path: '/barcode_report2',
        name: 'barcode_report2',
        component: barcodeReport2,
    },
    {
        path: '/qrcode_report2',
        name: 'qrcode_report2',
        component: qrcodeReport2,
    },
    {
        path: '/opac-hits',
        name: 'opac-hits',
        component: opachits,
    },
    {
        path: '/over-due',
        name: 'over-due',
        component: OverDueMember,
    },
    {
        path: '/daily-transaction',
        name: 'daily-transaction',
        component: DailyTransaction,
    },
    {
        path: '/edition-wise-report',
        name: 'editonwisereport',
        component: editonwisereport,
    },
    {
        path: '/issued-book-data-report',
        name: 'issued-book-data-report',
        component: ExportUserData,
    },
    {
        path: '/accession_register_new',
        name: 'accession_register_new',
        component: accession_register_new,
    },
    {
        path: '/memberwise-report',
        name: 'memberwise-report',
        component: MemberWise,
    },
    {
        path: '/periodical-report',
        name: 'periodical-report',
        component: AddPeriodicalReport,
    },
    {
        path: '/addbook-report',
        name: 'addbook-report',
        component: AddBookReport,
    },
    {
        path: '/user-profile',
        name: "user-profile",
        component: UserProfile,

    },
    {
        path: '/title-report',
        name: 'title-report',
        component: TitleReport,
    },
    {
        path: '/missing-book-report',
        name: 'missing-book-report',
        component: missing_book_report,
    },
    {
        path: '/departmentwise_book_report',
        name: 'departmentwise_book_report',
        component: DepartmentwiseBookReport,
    },
    {
        path: '/series-report',
        name: 'series-report',
        component: SeriesWiseReport,
    },
    {
        path: '/pay-fine',
        name: 'pay-fine',
        component: PayFine,
    },
    {
        path: '/upload-question-paper',
        name: 'upload-question-paper',
        component: UploadQuestionPaper,
    },
    {
        path: '/download-question-paper',
        name: 'download-question-paper',
        component: DownloadQuestionPaper,
    },
    {
        path: '/total_fine_student_wise',
        name: 'total_fine_student_wise',
        component: total_fine_student_wise,
    },
    ]

}];
export default routes;